import {
  default as CameraPhoto,
  CaptureConfigOption,
  default as LibCameraPhoto,
} from '@/lib/jslib-html5-camera-photo/index.js'

import { RefObject, useCallback, useEffect, useState } from 'react'
import shutterSound from '/camera-shutter.mp3'
import { captureException } from '@sentry/react'

type FacingMode = 'user' | 'environment'
const shutterAudio = new Audio(shutterSound)

interface Resolution {
  height?: MediaTrackConstraints['height'] | undefined
  width?: MediaTrackConstraints['width'] | undefined
}

export function useLibCameraPhoto(
  videoRef: RefObject<HTMLVideoElement>,
  idealFacingMode: FacingMode,
  idealResolution: Resolution,
  isMaxResolution: boolean,
  enabled: boolean = true,
) {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [libCameraPhoto, setLibCameraPhoto] = useState<CameraPhoto | null>(null)

  const enableStream = useCallback(async () => {
    if (!libCameraPhoto || !enabled) return

    try {
      let _mediaStream = null
      if (isMaxResolution) {
        _mediaStream = await libCameraPhoto.startCameraMaxResolution(idealFacingMode)
        // _mediaStream = await libCameraPhoto.startCamera(idealFacingMode) // for testing
      } else {
        _mediaStream = await libCameraPhoto.startCamera(idealFacingMode, idealResolution)
      }

      if (videoRef && videoRef.current && _mediaStream) {
        setMediaStream((prevMediaStream) => {
          if (prevMediaStream) {
            prevMediaStream.getTracks().forEach((track) => track.stop())
          }
          return _mediaStream
        })
      }
    } catch (error) {
      console.error('error', error)
      captureException(error, {
        level: 'error',
        extra: {
          description: 'Error starting camera',
          isMaxResolution,
          idealFacingMode,
          idealResolution,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaxResolution, libCameraPhoto, idealFacingMode, idealResolution])

  const stopStream = useCallback(async () => {
    if (!mediaStream) return

    mediaStream.getTracks().forEach((track) => track.stop())
  }, [mediaStream])

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const _libCameraPhoto = new LibCameraPhoto(videoRef.current)
      setLibCameraPhoto(_libCameraPhoto)
    }
  }, [videoRef])

  useEffect(() => {
    const abortController = new AbortController()

    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) {
          stopStream()
        } else {
          enableStream()
        }
      },
      { signal: abortController.signal },
    )

    return () => {
      stopStream()
      abortController.abort()
    }
  }, [stopStream])

  useEffect(() => {
    enableStream()

    return () => {
      stopStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libCameraPhoto])

  const getDataUri = useCallback(
    (configDataUri: CaptureConfigOption, silent?: boolean) => {
      if (!libCameraPhoto) return ''

      if (!silent) {
        shutterAudio.play()
      }
      return libCameraPhoto.getDataUri(configDataUri)
    },
    [libCameraPhoto],
  )

  return { mediaStream, getDataUri, stopStream, enableStream }
}
