import { ContentLayout } from '@/components/layouts/content-layout'
import { getOrganizationDetailsQueryOptions } from '@/features/make-payment/api/getOrganizationDetails'
import { MakePaymentB2BPage } from '@/features/make-payment/components/MakePaymentB2BPage'
import { QueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, redirect, useNavigate } from 'react-router-dom'

export const makePaymentB2BLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const token = params.token
    const searchParams = new URL(request.url).searchParams
    const providerUrl = searchParams.get('provider_url')
    const claimId = searchParams.get('claim_id')

    if (!providerUrl || !claimId || !token) {
      return redirect('/token-not-valid')
    }

    return queryClient.ensureQueryData(getOrganizationDetailsQueryOptions(providerUrl, token))
  }

export const MakePaymentB2BRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ContentLayout
      title="Make Payment"
      pageTitle={t('amount')}
      backClickCallback={() => {
        navigate(-1)
      }}>
      <MakePaymentB2BPage />
    </ContentLayout>
  )
}
