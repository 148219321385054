import KeraWithLabelIcon from '@/components/icons/KeraWithLabelIcon'
import PharmacyIcon from '@/components/icons/PharmacyIcon'
import TickCircleIcon from '@/components/icons/TickCircleIcon'
import { WHATSAPP_NUMBER } from '@/config'
import { useOrganizationDetails } from '@/features/make-payment/api/getOrganizationDetails'
import { useDecodeToken } from '@/features/token/api/decode-token'
import { cn } from '@/utils/cn'
import { ErrorEnum, getApiErrorDetail } from '@/utils/errors'
import { zodResolver } from '@hookform/resolvers/zod'
import { Badge, Button, Group, NumberInput, Stack, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { captureException } from '@sentry/react'
import { TFunction } from 'i18next'
import { useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { z } from 'zod'
import { useMakePayment } from '../api/makePayment'
import { ConfirmPaymentDrawer } from './ConfirmPaymentDrawer'
import { openLimitExceedModal } from './openLimitExceedModal'

const LIMIT = 35_000

const createFormSchema = (t: TFunction) => {
  return z.object({
    amount: z
      .number({
        required_error: t('amount_is_required'),
        invalid_type_error: t('amount_is_required'),
      })
      .min(1, { message: t('amount_cannot_be_0') })
      .max(999_999, {
        message: t('maximum_amount_allowed_exceeded'),
      }),
  })
}

type FormValues = z.infer<ReturnType<typeof createFormSchema>>

export const MakePaymentB2BPage = () => {
  const { t } = useTranslation()
  const isKeyboardOpen = useDetectKeyboardOpen()

  const [confirmDrawerOpened, { open: openConfirmDrawer, close: closeConfirmDrawer }] = useDisclosure(false)
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false)

  const token = useParams().token as string
  const { data: decodedToken } = useDecodeToken(token)
  const { data: organizationDetails } = useOrganizationDetails({})

  const formSchema = useMemo(() => createFormSchema(t), [t])
  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const makePaymentMutation = useMakePayment()

  const onSubmit = handleSubmit((data) => {
    const isDigitizedLimitExceeded =
      decodedToken?.is_digitized && decodedToken.approved_amount && data.amount > decodedToken.approved_amount
    const isNonDigitizedLimitExceeded = !decodedToken?.is_digitized && data.amount > LIMIT

    if (isDigitizedLimitExceeded || isNonDigitizedLimitExceeded) {
      openLimitExceedModal()
      return
    }

    openConfirmDrawer()
  })

  const onConfirm = (amount: number) => {
    if (!decodedToken?.claim_id || !organizationDetails?.m_id) return

    const payload = {
      isB2BPayout: true,
      token: token,
      claimId: decodedToken.claim_id,
      merchantId: organizationDetails.m_id,
      amount,
    }

    makePaymentMutation.mutate(payload, {
      onSuccess: () => {
        setIsPaymentCompleted(true)
        closeConfirmDrawer()
      },
      onError: (_error) => {
        captureException(_error, {
          level: 'error',
          extra: {
            description: 'Error making payment',
            payload,
          },
        })
        const error = getApiErrorDetail(_error)

        if (error.error_code === ErrorEnum.SIMILAR_TRANSACTION_ONGOING) {
          toast.error(t(ErrorEnum.SIMILAR_TRANSACTION_ONGOING))
        } else {
          toast.error(t('error_while_making_payment'))
        }
      },
    })
  }

  if (!organizationDetails) return null

  return (
    <Stack flex="1" component="form" onSubmit={onSubmit}>
      <FormProvider {...formMethods}>
        <ConfirmPaymentDrawer opened={confirmDrawerOpened} onClose={closeConfirmDrawer} onConfirm={onConfirm} />
        <Stack mt={32} align="center">
          <KeraWithLabelIcon />
          <Stack mt={isKeyboardOpen ? 24 : 56} align="center">
            <PharmacyIcon />
            <Badge variant="outline" fw={500}>
              {organizationDetails.name}
            </Badge>
          </Stack>
          <Stack w="100%" mt={32} gap={0}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <NumberInput
                  inputMode="decimal"
                  disabled={isPaymentCompleted}
                  error={errors.amount?.message as string}
                  w="100%"
                  hideControls
                  label={t('enter_total_amount')}
                  thousandSeparator=","
                  styles={{
                    error: {
                      fontWeight: 600,
                    },
                  }}
                  classNames={(theme, props) => ({
                    label: cn({
                      'text-base-darkgray-disabled': props.disabled,
                    }),
                  })}
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        {isPaymentCompleted && (
          <Stack my="auto" align="center" gap={0}>
            <TickCircleIcon />
            <Title mt={8} order={2} size={28} fw={700} c="#049559">
              {t('success')}
            </Title>
            <Text mt={16} fw={600} c="base-black.0">
              {t('success_paid')}
            </Text>
            <Text mt={16} size="sm">
              {t('check_whatsapp_for_receipt')}
            </Text>
          </Stack>
        )}
        <Group mt={isKeyboardOpen ? '0' : 'auto'}>
          {isPaymentCompleted ? (
            <Button
              component="a"
              href={`https://wa.me/${WHATSAPP_NUMBER}?text=%20`}
              target="_blank"
              color="alerts-green"
              fullWidth>
              {t('back_to_home')}
            </Button>
          ) : (
            <Button type="submit" fullWidth>
              {t('finish')}
            </Button>
          )}
        </Group>
      </FormProvider>
    </Stack>
  )
}
