import { PhoneInput } from '@/components/ui/phone-input/PhoneInput'
import { ScanQRPage } from '@/features/scan-qr-code/components/ScanQRPage'
import { ReceiptScannerPage } from '@/features/scan-receipt/components/ReceiptScannerPage'
import { DecodeToken } from '@/features/token/types'
import { queryClient } from '@/lib/react-query'
import { ErrorEnum, getApiErrorDetail } from '@/utils/errors'
import { Group } from '@mantine/core'
import { createBrowserRouter, redirect } from 'react-router-dom'
import { makePaymentB2BLoader } from './payment/make-payment-b2b'
import { tokenLoader } from './payment/verify-token'
import { MakePaymentB2CPage } from '@/features/make-payment/components/MakePaymentB2CPage'
import * as Sentry from '@sentry/react'
import { MakePaymentPlayground } from '@/features/make-payment/components/MakePaymentPlayground'

export const router = createBrowserRouter([
  {
    path: '/',
    loader: async () => {
      return redirect('/token-not-valid')
    },
  },
  {
    path: '/:token',
    loader: async (args) => {
      const result = (await tokenLoader(queryClient)(args)) as DecodeToken

      if ('ok' in result && !(result as unknown as Response).ok) {
        return redirect('/token-not-valid')
      }

      if (result.used) {
        return redirect(`/payment/scan-receipt/${args.params.token}`)
      } else if (!result.used && !result.receipt_sent) {
        return redirect(`/payment/scan-qr-code/${args.params.token}`)
      }

      return redirect('/token-not-valid')
    },
  },
  {
    path: '/payment',
    loader: tokenLoader(queryClient),
    children: [
      {
        path: 'scan-qr-code/:token',
        lazy: async () => {
          const { ScanQRCodeRoute } = await import('./payment/scan-qr-code')

          return { Component: ScanQRCodeRoute }
        },
      },
      {
        path: 'make-payment-b2b/:token',
        loader: async (args) => {
          try {
            const tokenResult = (await tokenLoader(queryClient)(args)) as DecodeToken
            if (tokenResult.used) {
              return redirect(`/payment/scan-receipt/${args.params.token}`)
            }

            const result = await makePaymentB2BLoader(queryClient)(args)

            return result
          } catch (_error) {
            const error = getApiErrorDetail(_error)

            if (error.error_code === ErrorEnum.INVALID_UNAUTHORIZED_QRCODE) {
              return redirect('/merchant-not-valid?token=' + args.params.token)
            }

            return redirect('/token-not-valid')
          }
        },
        lazy: async () => {
          const { MakePaymentB2BRoute } = await import('./payment/make-payment-b2b')

          return { Component: MakePaymentB2BRoute }
        },
      },
      {
        path: 'make-payment-b2c/:token',
        loader: async (args) => {
          const tokenResult = (await tokenLoader(queryClient)(args)) as DecodeToken
          if (tokenResult.used) {
            return redirect(`/payment/scan-receipt/${args.params.token}`)
          } else if (!tokenResult.used && !tokenResult.receipt_sent) {
            return tokenResult
          }

          return redirect('/token-not-valid')
        },
        lazy: async () => {
          const { MakePaymentB2CRoute } = await import('./payment/make-payment-b2c')

          return { Component: MakePaymentB2CRoute }
        },
      },
      {
        path: 'scan-receipt/:token',
        lazy: async () => {
          const { ScanReceiptRoute } = await import('./payment/scan-receipt')

          return { Component: ScanReceiptRoute }
        },
      },
    ],
  },
  {
    path: '/coverage',
    children: [
      {
        path: 'upload-images/:claim_id',
        loader: async (args) => {
          Sentry.setTag('claim_id', args.params.claim_id)
          return {}
        },
        lazy: async () => {
          const { UploadImagesRoute } = await import('./coverage/upload-images')
          return { Component: UploadImagesRoute }
        },
      },
    ],
  },
  // Debug routes
  ...(import.meta.env.DEV
    ? [
        {
          path: '/receipt',
          element: <ReceiptScannerPage />,
        },
        {
          path: '/scanner',
          element: <ScanQRPage />,
        },
        {
          path: '/b2c',
          element: <MakePaymentB2CPage />,
        },
        {
          path: '/components',
          element: (
            <div className="flex items-center justify-center h-screen">
              <Group>
                <PhoneInput value={'5462430555'} countryCode={'tr'} onChange={() => {}} />
              </Group>
            </div>
          ),
        },
      ]
    : []),
  ...(import.meta.env.MODE === 'development'
    ? [
        {
          path: '/payment/make-payment-playground',
          element: <MakePaymentPlayground />,
        },
      ]
    : []),
  {
    path: '/token-not-valid',
    lazy: async () => {
      const { TokenNotValidRoute } = await import('./payment/verify-token')

      return { Component: TokenNotValidRoute }
    },
  },
  {
    path: '/merchant-not-valid',
    lazy: async () => {
      const { MerchantNotValidRoute } = await import('./payment/merchant-not-valid')

      return { Component: MerchantNotValidRoute }
    },
  },

  {
    path: '*',
    loader: async () => {
      return redirect('/token-not-valid')
    },
  },
])
