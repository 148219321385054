import {
  Badge,
  Button,
  CSSVariablesResolver,
  Card,
  Checkbox,
  Input,
  InputWrapper,
  PasswordInput,
  PinInput,
  Select,
  TextInput,
  createTheme,
  defaultCssVariablesResolver,
  defaultVariantColorsResolver,
  rem,
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { Calendar } from 'iconsax-react'
import { alertsGreen, alertsRed, baseBlack, baseBlue, baseDarkgray } from './colors'
import badgeStyles from './styles/badge.module.css'
import buttonStyles from './styles/button.module.css'
import checkboxStyles from './styles/checkbox.module.css'
import inputStyles from './styles/input.module.css'

export const theme = createTheme({
  fontFamily: 'Inter',
  colors: {
    'base-blue': baseBlue,
    'alerts-red': alertsRed,
    'alerts-green': alertsGreen,
    'base-darkgray': baseDarkgray,
    'base-black': baseBlack,
  },
  primaryColor: 'base-blue',
  defaultRadius: 'md',
  black: 'var(--mantine-color-base-black-0)',
  components: {
    Button: Button.extend({
      classNames: {
        root: buttonStyles.root,
      },
      defaultProps: {
        radius: 'md',
        size: 'md',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxStyles,
      defaultProps: {
        radius: 'sm',
      },
    }),
    InputWrapper: InputWrapper.extend({
      classNames: {
        label: 'font-semibold',
        required: 'text-black',
        error: 'font-semibold',
      },
    }),
    Input: Input.extend({
      classNames: inputStyles,
      defaultProps: {
        // size: 'md',
      },
    }),
    TextInput: TextInput.extend({
      classNames: inputStyles,
      defaultProps: {
        // size: 'md',
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        // size: 'md',
      },
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        // size: 'md',
        rightSection: <Calendar size={16} />,
      },
    }),
    PinInput: PinInput.extend({
      classNames: {
        input: 'placeholder:!not-italic',
      },
      defaultProps: {
        // size: 'lg',
      },
    }),
    Select: Select.extend({
      defaultProps: {
        // size: 'md',
      },
    }),
    Badge: Badge.extend({
      classNames: badgeStyles,
      defaultProps: {
        radius: 'sm',
        size: 'md',
        tt: 'none',
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: 'lg',
      },
    }),
  },
  variantColorResolver: (input) => {
    return defaultVariantColorsResolver(input)
  },
})

export const cssVariableResolver: CSSVariablesResolver = (theme) => ({
  ...defaultCssVariablesResolver(theme),
  light: {
    ...defaultCssVariablesResolver(theme).light,
    '--mantine-color-error': theme.colors['alerts-red'][6],
  },
})
