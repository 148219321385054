import { MantineColorsTuple } from '@mantine/core'

export const baseBlue: MantineColorsTuple = [
  '#e8efff',
  '#cedaff',
  '#9cb1ff',
  '#6685fd',
  '#3a60fc',
  '#1e49fb',
  '#0c3dfc',
  '#0030e1',
  '#002aca',
  '#0023b2',
]

export const alertsRed: MantineColorsTuple = [
  '#E6B2BB',
  '#FF7A90',
  '#FF516E',
  '#FF284C',
  '#FF002B',
  '#D60024',
  '#AD001D',
  '#8C0017',
  '#3D000A',
  '#050001',
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const alertsGreen: MantineColorsTuple = [
  '#45FF6C',
  '#31FF5C',
  '#08FF3C',
  '#00DE2F',
  '#00B626',
  '#008D1E',
  '#006415',
  '#002C09',
  '#000000',
  '#000000',
]

export const baseDarkgray: MantineColorsTuple = [
  '#C6C9D3',
  '#BBBECA',
  '#A4A8B8',
  '#8D93A6',
  '#767D94',
  '#63697F',
  '#515668',
  '#383C48',
  '#202229',
  '#070809',
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const baseBlack: MantineColorsTuple = ['#0A0908'] as any
