/* eslint-disable react-refresh/only-export-components */
import { AGENT_NUMBER } from '@/config'
import { Box, Button, Divider, FocusTrap, Group, Stack, Text, Title } from '@mantine/core'

import { modals } from '@mantine/modals'
import i18next from 'i18next'
import { InfoCircle } from 'iconsax-react'

const t = i18next.t
export const openLimitExceedModal = () =>
  modals.open({
    autoFocus: false,
    children: (
      <Stack className="overflow-hidden" gap={0}>
        <FocusTrap.InitialFocus />
        <Stack p={16}>
          <Group>
            <Box w={40} h={40} p={10} className="rounded-full bg-[#FFF6EB]">
              <InfoCircle color="#8B4E0E" size={20} />
            </Box>
          </Group>
          <Title order={3} size={18}>
            {t('amount_exceeds_limit')}
          </Title>
          <Text c="base-darkgray" fw={500}>
            {t('amount_exceeds_limit_message')}
          </Text>
        </Stack>
        <Divider orientation="horizontal" flex={0} />
        <Box>
          <Group className="[&>*]:flex-1 [&>*]:rounded-none [&>*]:active:!transform-none" gap={0}>
            <Button
              variant="subtle"
              color="base-darkgray"
              fw={700}
              className="!rounded-none"
              onClick={() => modals.closeAll()}>
              {t('close')}
            </Button>
            <Divider orientation="vertical" flex={0} />
            <Button
              component="a"
              href={`tel:${AGENT_NUMBER}`}
              target="_blank"
              variant="subtle"
              color="base-blue"
              fw={700}>
              {t('call_agent')}
            </Button>
          </Group>
        </Box>
      </Stack>
    ),
    centered: true,
    withCloseButton: false,
    radius: 'lg',
    styles: {
      body: {
        padding: 0,
      },
    },
  })
